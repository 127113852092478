@import "../../common/styleConstants.scss";

.container {
  height: calc(50vh - 100px);

  .chartText {
    font-size: 13px;
    font-family: "Nunito", sans-serif;
    text-shadow: 0 0 10px #000;
    font-weight: bold;
    fill: lightgray;
  }
}

.axis {
  text {
    fill: #909090;
    font-size: 12px;
  }

  line,
  path {
    stroke: #323b69;
  }
}

.lineTop {
  stroke: #323b69;
  fill: transparent;
}

.title {
  font-size: 1.1em;
  color: white;
}

.row {
  justify-content: space-between;
}
