@import "../../common/styleConstants.scss";

.previousNextButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 20px;
  right: 20px;

  .ant-tooltip-disabled-compatible-wrapper {
    border-color: transparent !important;
  }

  .leftButton {
    background-color: $primary-color;
    border-color: $primary-color;
    color: $title-color;
    height: 28px;
    line-height: 26px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }

  .rightButton {
    background-color: $primary-color;
    border-color: $primary-color;
    color: $title-color;
    height: 28px;
    line-height: 26px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .disabledButton {
    background: $disabled-color !important;
    border: 1px solid $disabled-color !important;
    color: $primary-color !important;
    cursor: not-allowed;
  }

  .icon {
    color: $disabled-color;
  }

  .disabledIcon {
    color: $primary-color;
  }
}
