@import "../../common/styleConstants.scss";

.value {
  font-size: 1.35em;
  color: $title-color;
  text-align: center;
}

.field {
  font-size: 0.9em;
  color: lightgray;
  text-align: center;
}

@media (max-width: 540px) {
  .value {
    font-size: 1em;
  }

  .field {
    font-size: 0.75em;
  }
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gridContainer {
  width: 100%;
  height: calc(50vh - 48px);
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: auto;
  row-gap: 10px;

  div {
    display: grid;
    grid-template-columns: auto auto auto;
    background-color: $content-background;
    border-radius: 8px;

    .gridItem {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 6px;
    }

    @media (max-width: 767px) {
      .gridItem {
        padding: 10px;
      }
    }
  }
}
