@import "./../../styleConstants.scss";

.fullScreenFrame {
  position: fixed;
  top: 0;
  left: 0;
  background-color: $body-background;
  width: 100vw;
  min-height: 100vh;
  z-index: 9999;

  .header {
    padding: 15px;
    background-color: $content-background;
    display: flex;
    justify-content: space-between;
    position: relative;

    .title {
      font-size: 1.3em;
    }

    .close {
      position: absolute;
      cursor: pointer;
      right: 25px;
      top: 12px;

      * {
        font-size: 1.3em;
      }

      &:hover {
        color: white;
      }
    }
  }

  @media (max-width: 540px) {
    .header {
      padding: 0;
      margin: 0 auto;
    }

    .title {
      width: 100%;
      text-align: center;
      font-weight: bold;
    }

    .close {
      top: 0 !important;
    }
  }
}
