
.ant-layout-header {
  line-height: 64px !important;
  height: auto;
}

.ant-divider-horizontal {
  margin: 10px 0 !important;
  background-color: grey;
}

.ant-btn {
  &:disabled {
    border-color: grey !important;
  }
}

.maturationComparisonModal {
  width: 100% !important;
  padding: 20px;
  
  .ant-modal-content {
    width: 100%;
    left: 0 !important;

    .ant-modal-body {
      padding: 0 0 15px;
      min-height: calc(100vh - 94px);
    }

    .ant-modal-footer {
      display: none;
    }
  }
}