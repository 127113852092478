.container {
  width: 100%;
  height: calc(50vh - 100px);
  overflow: hidden;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.notAvailable {
  width: 100%;
  height: calc(50vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 1.1em;
  color: white;
}

.row {
  justify-content: space-between;
  padding-bottom: 10px;
}
