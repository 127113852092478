@import "../../common/styleConstants.scss";

.spinner {
  position: absolute;
  width: calc(100%);
  height: calc(100%);
  bottom: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(31, 41, 64, 0.6);

  i {
    font-size: 40px;
  }
}

.containerMain {
  width: 100%;
  height: 90vh;

  .body {
    height: calc(100vh - 74px);
    overflow-y: scroll;
  }

  .histogram {
    .title {
      color: transparent;
    }
  }

  .containerBarsChart {
    height: calc(100vh - 183px);
  }

  .chartTextBarsChart {
    font-size: 13px;
    font-family: "Nunito", sans-serif;
    text-shadow: 0 0 10px #000;
    font-weight: bold;
    fill: lightgray;
  }

  .chartTextFillWhite {
    font-size: 13px;
    font-family: "Nunito", sans-serif;
    fill: white;
    text-shadow: 0 0 10px #000;
    font-weight: bold;
  }

  @media (max-width: 1200px) {
    .body {
      height: calc(100vh - 114px);
      overflow-y: scroll;
    }

    .histogramColumn {
      order: 1;

      .histogram {
        padding-top: 10px;
      }
    }

    .barsChartColumn {
      order: 2;

      .postlarvaePerGroup {
        padding-top: 10px;
      }
    }

    .sampleColumn {
      order: 3;
      display: flex;

      .sample {
        order: 2;
        width: 50%;
        margin-left: 6px;
      }

      .map {
        width: 50%;
        margin-right: 6px;
      }
    }
  }

  @media (max-width: 767px) {
    .histogramColumn {
      order: 1;
    }

    .barsChartColumn {
      order: 3;

      .postlarvaePerGroup {
        padding-top: 0px;
      }
    }

    .sampleColumn {
      padding-top: 10px;
      order: 2;
      display: block;

      .sample {
        width: 100%;
        margin-right: 0px;
      }

      .map {
        width: 100%;
        margin-left: 0px;
      }
    }
  }
}

.showResult {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px auto;
}
