@import "./common/styleConstants.scss";

#root {
  height: 100%;

  > .ant-layout {
    height: 100%;
  }
}

.superAdminAction {
  color: darkorange;
}

.ant-select-selection-placeholder {
  color: #777 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
input:-webkit-autofill::first-line {
  font-size: 14px;
}

body {
  margin: 0;
  background-color: $body-background !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  * {
    font-family: $font-family;
    font-size: 15px;
    letter-spacing: 0.3px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.login-form {
  .ant-input-affix-wrapper {
    padding-top: 8px;
  }
}

.ant-layout-header {
  padding: 0 !important;
}

.ant-layout-sider-children .ant-menu-item {
  margin-left: -5px !important;
  justify-content: center !important;
  height: 50px !important;

  i {
    font-size: 24px;
  }
  svg {
    width: 22px;
    height: 22px;
  }
}

.ant-table {
  background: unset !important;
}
.ant-table-thead th {
  color: $title-color !important;
}
.ant-table-tbody > tr > td {
  border-color: $border-color-light !important;
}
.ant-table-thead > tr > th {
  border-bottom: none !important;
  font-weight: bold !important;
}

.ant-btn i {
  font-size: 1.2em;
}

.ant-btn-text {
  &:hover {
    background-color: $body-background !important;
  }
}

.ant-btn-text[disabled] {
  color: white !important;
}

.ant-btn span {
  margin-top: 1px;
}

.ant-btn-link {
  color: #dedede !important;
}

.ant-modal-footer > div {
  width: 100%;
  display: flex !important;
  justify-content: flex-end;
}
.ant-modal-header {
  border-bottom-color: $border-color-light !important;
}
.ant-modal-footer {
  border-top-color: $border-color-light !important;
  display: flex;
  justify-content: flex-end;
  padding: 20px 25px !important;

  .ant-btn {
    text-transform: uppercase;
  }
}
.ant-modal-title {
  font-weight: bold !important;
  font-size: 1.2em !important;
}
.ant-modal-mask {
  background-color: rgba(255, 255, 255, 0.2) !important;
}
.ant-modal-close-x {
  i {
    color: white !important;
    font-size: 1.5em !important;
  }
}
.ant-notification-notice-message {
  color: $title-color !important;
}
.ant-notification-close-x i {
  color: $title-color !important;
  font-size: 1.2em !important;
}
.ant-notification-notice-icon i {
  font-size: 1em !important;
  color: limegreen;
}
.ant-layout-header .ant-menu {
  display: flex;
  width: 75px;
}

::-webkit-scrollbar {
  width: 4px !important;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #666;
  border-radius: 3px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ant-select {
  font-weight: normal !important;
  .ant-select-arrow {
    top: 48% !important;
    color: #646974;
  }
}
.ant-select-item-option-active {
  color: white !important;
}

.ant-select-item-group {
  color: $title-color !important;
}

.ant-layout {
  background-color: $body-background !important;
}

.ant-select-item-option {
  background-color: $body-background;
}

.ant-select-item-option-disabled {
  background-color: $content-background;
  color: grey;
}

.ant-select-item-option-selected {
  background-color: $body-background !important;
}

.ant-picker {
  width: 100%;
}

.ant-picker-header {
  color: white !important;
}

.ant-picker-header button {
  color: white !important;
}

.ant-picker-cell {
  color: #646974 !important;
}

.ant-picker-cell-inner {
  &:hover {
    background: $primary-color !important;
  }
}

.ant-picker-cell-in-view {
  color: #dedede !important;
}

.ant-picker-cell-disabled {
  &:before {
    background: #c4c4c4 !important;
  }
}

.ant-picker-suffix {
  > span {
    > svg {
      fill: #646974 !important;
    }
  }
}

.ant-picker-today-btn {
  color: white !important;
}

.ant-picker-today-btn-disabled {
  color: grey !important;
}

textarea {
  resize: none !important;
}

.ant-input-number {
  width: 100% !important;
  border: 1px solid transparent !important;
}

.ant-input-number-handler-up-inner .ant-input-number-handler-down-inner {
  > svg {
    fill: white !important;
  }
}

.ant-input-number-handler-wrap {
  background: $content-background !important;
}

.ant-tabs-tab-disabled {
  color: grey !important;
}

.ant-select-disabled.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  color: #dedede !important;
  background: #141b2d !important;
  cursor: not-allowed;
}

.ant-select-multiple .ant-select-selection-item {
  background: #1f2940 !important;
  border: 1px solid #1f2940 !important;
}
// Pagination

.ant-pagination-item,
.ant-pagination-item-link {
  border: none !important;
}

.ant-pagination-item-active {
  border: 1px solid $primary-color !important;
}

.ant-pagination-jump-next {
  .ant-pagination-item-ellipsis {
    color: $title-color !important;
    font-size: 10px;
  }
}

.ant-pagination-disabled {
  svg {
    fill: $title-color;
  }
}

.ant-radio-button-wrapper {
  border-color: #141b2d !important;

  &:not(first-child) {
    &:before {
      background-color: $primary-color !important;
    }
  }
}

.ant-radio-button-wrapper-checked {
  color: #dedede !important;
  border-color: $primary-color !important;
  background-color: $primary-color !important;

  &:before {
    content: initial !important;
  }

  &:not(.ant-radio-button-wrapper-disabled) {
    box-shadow: none !important;
  }
}

.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: none !important;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: $content-background !important;
}

.ant-dropdown-menu-item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  > i {
    margin-right: 10px !important;
  }

  > span {
    width: 100%;
    text-align: left;
  }
}

.ant-btn-primary {
  &:disabled {
    background-color: $disabled-color !important;
    color: $inactive-status-color !important;
  }
}

.ant-input-password {
  .ant-input-suffix {
    > span {
      color: white;
    }
  }
}

.ant-input-suffix {
  .ant-input-search-icon {
    color: #6b6b6b;
  }
}

.ant-select-selection-item-remove {
  color: white !important;
  display: flex !important;
  align-items: center !important;
  font-weight: normal !important;
}

.ant-popover-title {
  color: white !important;
}

.ant-tabs-nav {
  &:before {
    border-width: 2px !important;
    border-color: $body-background !important;
  }

  .ant-tabs-nav-wrap {
    .ant-tabs-nav-list {
      .ant-tabs-tab {
        border-width: 2px;
        border-color: $body-background !important;
      }
      .ant-tabs-tab-active {
        border-bottom-color: transparent !important;
        .ant-tabs-tab-btn {
          color: $title-color !important;
        }
      }
    }
  }
}

.ant-input-number {
  .ant-input-number-handler-wrap {
    border-left-color: #a9a9a9;
    svg {
      fill: #a9a9a9 !important;
      height: 10px;
    }
    .ant-input-number-handler-down {
      svg {
        margin-top: 1px;
      }
    }
  }
}

.ant-switch {
  background-color: grey !important;

  .ant-switch-inner {
    i {
      line-height: 1.6;
    }
  }

  .ant-switch-handle {
    &:before {
      background-color: $content-background;
    }
  }

  &.ant-switch-checked {
    background-color: limegreen !important;
  }
}

.ant-pagination-item-ellipsis {
  color: rgba(255, 255, 255, 0.75) !important;
}

.ant-result {
  .ant-result-icon {
    span {
      svg {
        width: 6em;
        height: 6em;
      }
    }
  }

  .ant-result-title {
    color: $title-color;
  }

  @media (max-width: 700px) {
    .ant-result-title {
      font-size: 15px;
    }
  }
}

.ant-breadcrumb {
  .ant-breadcrumb-link {
    a {
      color: $title-color;
    }
  }

  .ant-breadcrumb-separator {
    color: #7d7d7d;

    .ri-arrow-right-s-line {
      vertical-align: bottom;
    }
  }

  @media (max-width: 660px) {
    .ant-breadcrumb-separator {
      margin: 0;
    }
  }
}

.ant-tooltip {
  z-index: 9999 !important;

  .ant-tooltip-content {
    .ant-tooltip-inner {
      background-color: rgba($color: #000000, $alpha: 0.9);
      font-size: 12px !important;
      text-align: center;
    }
  }
}

.ant-input-lg {
  padding: 4px 11px !important;
}

.ant-input-group-addon {
  background-color: transparent !important;
}

.ant-input-search-button {
  height: 35px !important;
}

.ant-tag {
  color: white;
  font-weight: bold;
  text-shadow: 0 0 4px black;
}

.ant-table-filter-trigger-container-open {
  background: $content-background !important;
}

.ant-table-filter-trigger {
  &:hover {
    background: $content-background !important;
  }
}

.ant-modal-confirm-title {
  color: #dedede !important;
}

.ant-modal-confirm-btns {
  display: flex !important;
}

.ant-modal-confirm-body {
  .anticon {
    color: #dedede !important;
  }
}

.anticon-exclamation-circle {
  svg {
    font-size: 24px;
  }
}

.ant-popover-message {
  display: flex;
}

.ant-collapse {
  background-color: $body-background !important;
  border-color: #5d5d5d !important;

  > .ant-collapse-item {
    border-color: #5d5d5d !important;

    > .ant-collapse-header {
      color: $title-color !important;
    }
  }
}

.ant-collapse-content {
  border-color: #5d5d5d !important;
}

.ant-collapse-content-box {
  background-color: $content-background !important;
}
