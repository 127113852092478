.resetBalance {
  color: #7264bb;
}

.addCompany {
  color: #7264bb;
}

.addUser {
  color: #7264bb;
}

.accreditation {
  color: #4f9b73;
}

.debit {
  color: #a85f66;
}

.userInactivation {
  color: #a85f66;
}

.pendingReview {
  color: #e0b927;
}

.approved {
  color: #4f9b73;
}

.rejected {
  color: #a85f66;
}

.action {
  div {
    i {
      font-size: 1.2em;
    }
  }
  :hover {
    text-decoration: none !important;
  }
}

.arrow {
  font-size: 1.4em;
}
