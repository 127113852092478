@import './../../styleConstants.scss';

.spinner {
  width: 70px;
  text-align: center;

  > div {
    width: 10px;
    height: 10px;
    margin-right: 4px;
    background-color: white;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1s infinite ease-in-out both;
    animation: sk-bouncedelay 1s infinite ease-in-out both;
  }

  .dot1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .dot2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  .dot3 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
}
