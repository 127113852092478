@import "../../common/styleConstants.scss";

.ant-row {
  .ant-col {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
}

.ant-switch {
  background-color: grey;
}

.ant-switch-checked {
  background-color: $primary-color;
}

.ant-picker-suffix {
  span {
    svg {
      fill: white !important;
    }
  }
}

.commercialSizesTabs {
  .ant-tabs-nav {
    margin-left: 15px;
    .ant-tabs-nav-wrap {
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          padding-top: 0px;
          padding-bottom: 6px;
        }
      }
    }
  }
}
