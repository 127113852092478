@import "./../../styleConstants.scss";

.lrvIcon {
  margin: 0 !important;
  vertical-align: middle;
}

.spin {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  width: auto;
  height: auto !important;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spy {
  font-size: 1.2em;
}
