@import "../../common/styleConstants.scss";

.loading {
  height: calc(50vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  height: calc(50vh - 100px);

  .actions {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 12px;
    right: 20px;
  }

  .previousNextButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 20px;
    right: 20px;

    .ant-tooltip-disabled-compatible-wrapper {
      border-color: transparent !important;
    }

    .leftButton {
      background-color: $primary-color;
      border-color: $primary-color;
      color: $title-color;
      height: 28px;
      line-height: 26px;
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
    }

    .rightButton {
      background-color: $primary-color;
      border-color: $primary-color;
      color: $title-color;
      height: 28px;
      line-height: 26px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    .disabledButton {
      background: $disabled-color !important;
      border: 1px solid $disabled-color !important;
      color: $primary-color !important;
      cursor: not-allowed;
    }

    .icon {
      color: $disabled-color;
    }

    .disabledIcon {
      color: $primary-color;
    }
  }

  @media (max-width: 1200px) {
    .actions {
      top: 22px;
    }
  }
}

.legends {
  position: fixed;
  bottom: 12px;
  left: 50%;
  width: 420px;
  margin-left: -170px;
  border-radius: 8px;
  border: 1px solid $title-color;
  background-color: $body-background;
  padding: 8px 12px;
  display: flex;
  justify-content: space-between;

  .legend {
    display: flex;

    .icon {
      margin-right: 10px;
      margin-left: 2px;
      margin-top: 3px;
    }

    .item {
      color: white;
      font-size: 0.9em;
    }
  }
}

.tools {
  position: fixed;
  top: 80px;
  right: 15px;
  width: 350px;
  border-radius: 8px;
  border: 1px solid $title-color;
  background-color: $body-background;
  padding: 15px;

  .toolRow {
    padding-left: 10px;

    .col {
      display: flex;
      align-items: center;

      .toolCheckbox {
        display: flex;
        align-items: center;

        span {
          display: flex;
          align-items: center;

          &:first-child {
            margin-top: -4px;
          }
        }
      }
    }

    &:hover {
      background-color: $content-background;
      cursor: pointer;
    }

    .colorBox {
      margin-left: 20px;
      margin-right: 10px;
      display: inline-block;
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
    }

    .image {
      width: 14px;
      height: 14px;
    }
  }
}

.containerImage {
  width: 100%;
  cursor: all-scroll;
  position: relative;

  canvas {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}

.containerImageFullScreen {
  width: 100%;
  cursor: move;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  font-size: 1.1em;
  color: white;
}

.row {
  justify-content: space-between;
  padding-bottom: 10px;
}

.generateJuvenileMask {
  color: greenyellow;
}
