@import "../../common/styleConstants";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $body-background;
  padding: 0 15px 0 20px;
  height: 64px;
  width: 100%;
  z-index: 1000;

  .leftHeader {
    display: flex;
    align-items: center;
    height: 100%;

    .titlePublic {
      display: inline;
      font-weight: bold;
      font-size: 22px;
    }
  }

  .rightHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }
}
