@import "../../common/styleConstants.scss";

.limitsDefault {
  font-size: 12px;
  fill: lightgray !important;
}

.limitsPdf {
  font-size: 12px;
  fill: #585857 !important;
}

.ticksLimitsDefault {
  font-size: 11px;
  fill: lightgray !important;
}

.ticksLimitsPdf {
  font-size: 10px;
  fill: #585857 !important;
}

.frequenciesDefault {
  fill: lightgray;
  font-size: 14px !important;
}

.frequenciesPdf {
  fill: #585857;
  font-size: 14px !important;
}

.container {
  height: calc(50vh - 100px);

  .actions {
    position: absolute;
    top: 12px;
    right: 25px;
    left: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .select {
      width: 150px;
    }

    .typeHistogram {
      background-color: transparent;

      .border {
        border: 1px solid $primary-color !important;
      }

      .weight {
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        height: 28px;
        line-height: 26px;
        border-right-width: 0px !important;

        span {
          font-size: 14px;
        }
      }

      .length {
        height: 28px;
        line-height: 26px;
        border-left-width: 1px !important;
        border-right-width: 1px !important;

        span {
          font-size: 14px;
        }
      }

      .pigmentation {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 28px;
        line-height: 26px;
        border-left-width: 0px !important;

        span {
          font-size: 14px;
        }
      }
    }

    .bins {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      background-color: transparent;
      margin-left: 10px;

      .remove {
        background: $primary-color;
        border-color: $primary-color;
        color: $title-color;
        height: 28px;
        line-height: 26px;

        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        border: 1px solid $primary-color !important;

        span {
          font-size: 14px;
        }
      }

      .add {
        background: $primary-color;
        border-color: $primary-color;
        color: $title-color;
        height: 28px;
        line-height: 26px;

        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border: 1px solid $primary-color !important;

        span {
          font-size: 14px;
        }
      }

      .disabled {
        background: $disabled-color !important;
        border: 1px solid $disabled-color !important;
        color: $primary-color !important;
        cursor: not-allowed;
      }
    }
  }

  @media (max-width: 1200px) {
    .actions {
      top: 22px;
    }
  }

  @media (max-width: 352px) {
    .actions {
      .typeHistogram {
        .weight {
          height: 26px;
          line-height: 24px;

          span {
            font-size: 11px;
          }
        }

        .length {
          height: 26px;
          line-height: 24px;

          span {
            font-size: 11px;
          }
        }

        .pigmentation {
          height: 26px;
          line-height: 24px;

          span {
            font-size: 11px;
          }
        }
      }

      .bins {
        .remove {
          height: 26px;
          line-height: 24px;

          span {
            font-size: 11px;
          }
        }

        .add {
          height: 26px;
          line-height: 24px;

          span {
            font-size: 11px;
          }
        }
      }
    }
  }

  .containerAnimals {
    position: absolute;
    padding: 12px 20px 0px 20px;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 12px);
    background-color: transparent;

    .animals {
      padding: 4px;
      border-radius: 6px;
      border: 1.5px solid $primary-color !important;
      text-align: center;

      div {
        font-size: 11.5px;
      }
    }
  }
}

.title {
  color: white;
}

.row {
  justify-content: space-between;
}

.averageLine {
  stroke: #00ff3b;
  stroke-width: 2;
  stroke-dasharray: 6;
  fill: transparent;
}

.averageTextDefault {
  fill: #00ff3b;
  font-size: 13px;
}

.averageTextPdf {
  fill: #585857;
  font-size: 13px;
}
