@import "./../../styleConstants.scss";

.content {
  background: $content-background;
  border-radius: 8px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 26px;

    .title {
      font-size: 16px;
      font-weight: bold;
    }
  }

  .subtitle {
    font-size: 14px;
    font-weight: normal;
    padding: 0px 26px;
  }

  .body {
    padding: 10px 20px;
  }

  .noPadding {
    padding: 0;
  }
}
