$font-family: 'Nunito', sans-serif;
$content-background: #1f2940;
$body-background: #141b2d;
$title-color: #dedede;
$border-color: #313131;
$border-color-light: $body-background;
$active-status-color: #52c41a;
$inactive-status-color: grey;
$primary-color: #696ffb;
$disabled-color: #c7c7c7;
